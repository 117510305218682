<template>
  <div class="complaint-card" v-if="complaint">
    <div
      class="complaint-card-content"
      :style="`--color:${complaint.color ? complaint.color : '#ffcfcf'};--picture:url(${complaint.creator.picture})`"
    >
      <div class="complaint-card-left">
        <UserAvatar :user="complaint.creator" />
      </div>
      <div class="complaint-card-right">
        <div class="complaint-card-status">
          <div class="complaint-card-user">
            <div class="complaint-card-info-label">{{ complaint.creator.name }}</div>
            <div class="complaint-card-info-reason">
              <span class="label">{{ $locale["report"][complaint.reason] }}</span>
              <span class="post" @click="setQuery({ post_view: complaint.post._id })">{{ post_label(complaint) }}</span>
            </div>
          </div>
          <div class="complaint-card-resume">
            <div>
              <span class="mount tooltip">
                <span noevents class="tooltiptext tool-auto">
                  {{ complaint.hasResponse ? $locale["report"]["has_response"] : $locale["report"]["not_has_response"] }}
                </span>
                <iconic :name="`${complaint.hasResponse ? 'comment_check' : 'comment_exclamation_o'}`" :dcolor="complaint.hasResponse" />
              </span>
            </div>
            <div>
              <span class="mount_label">{{ card.numLabel }}</span>
            </div>
          </div>
        </div>
        <div class="complaint-card-info-message">
          <MessageBubble :data="{ user: complaint.user, date: complaint.date, message: complaint.message }" />
        </div>
        <div class="complaint-card-comments">
          <TextareaRich class="message-box" :placeholder="$locale['report']['add_answer']" @input="inputMessage" ref="textbox" />
          <div tcenter padding-top v-if="loading"><iconic name="gspinner" /></div>
          <Confirm :confirm="confirm" @cancel="answerCancel" @accept="answerSend" v-else-if="message" />
          <div class="answers-list">
            <MessageBubble
              v-for="answer in complaint.messages"
              :key="answer._id"
              :data="{ user: answer.user, date: answer.date, message: answer.message }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "complaint"],
  components: {
    MessageBubble: () => import("../widgets/MessageBubble.vue"),
  },
  data: function() {
    return {
      loading: false,
      target: null,
      message: "",
      confirm: {
        cancel: {
          label: this.words("cancel"),
          icon: "times",
        },
        accept: {
          label: this.words("accept"),
          icon: "check",
        },
      },
    };
  },
  methods: {
    answerCancel: function() {
      this.message = "";
      this.target.innerHTML = "";
    },
    inputMessage: function({ target }) {
      let [message] = [target.innerHTML];
      this.target = target;
      this.message = message;
    },
    post_label: function(complaint) {
      if (!complaint || !complaint.post) return "";
      let [postId] = [complaint.post._id];
      let [postLabel] = [postId.slice(-6)];
      return `${this.$locale["words"]["post"]}: #${postLabel}`;
    },
    answerSend: async function() {
      this.isLoading(true);
      try {
        const data = {
          complaint: this.complaint._id,
          answer: this.message,
        };
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}admin/complaints-answer`, data);
        this.answerSuccess(response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error, icon: "error" });
      }
      this.isLoading(false);
    },
    answerSuccess: function({ message, success, answer }) {
      if (success) {
        answer.user = this.$user;
        this.complaint.messages.unshift(answer);
        this.complaint.hasResponse = true;
        return this.answerCancel();
      }
      return this.ialert.go({ title: "Error", message: message, icon: "error" });
    },
    words: function(key) {
      return this.$store.state.locale.words[key];
    },
  },
};
</script>

<style lang="scss">
.complaint-card {
  position: relative;
  margin: 0 0 $mpadding 0;
  &-content {
    display: flex;
    width: 100%;
    background-color: #fff;
    @include shadow;
    border-radius: $mpadding/2;
  }
  &-left {
    display: flex;
    .user-avatar {
      margin: $mpadding;
    }
  }
  &-icon {
    background-color: var(--color);
    background-image: var(--picture);
    background-size: cover;
    background-position: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    font-size: var(--iconSize);
    margin: $mpadding;
    .iconic {
      @include Flex(inherit, center, center);
    }
  }
  &-info {
    padding: $mpadding $mpadding;
    display: flex;
    align-items: center;
    &-label {
      font-weight: bold;
      font-size: 105%;
    }
    &-reason {
      margin: $mpadding/2 0 0 0;
      font-weight: bold;
      font-size: 90%;
      display: flex;
      gap: $mpadding/2;
      .label {
        color: $primary-color;
      }
      .post {
        opacity: 0.7;
        cursor: pointer;
      }
    }
    &-message {
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      opacity: 0.8;
      .message-bubble.__admin {
        background-color: rgba(9, 30, 66, 0.1);
      }
    }
    .progress-bar {
      margin-top: $mpadding/2;
    }
  }
  &-right {
    flex: 1;
    padding: $mpadding 0 $mpadding 0;
    .mount {
      font-size: 120%;
      font-weight: bold;
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      .iconic {
        display: inline-flex;
        margin: 0 0 $mpadding/2 0;
      }
    }
    .sign {
      font-weight: bold;
      margin: 0 $mpadding/4 0 0;
      font-size: 80%;
    }
    .mount_label {
      margin: 0 $mpadding $mpadding/2 0;
      font-size: 85%;
      opacity: 0.7;
      display: block;
    }
    .tooltiptext {
      font-size: 70%;
    }
  }
  &-status {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  &-resume {
    text-align: right;
  }
  &-comments {
    padding: 0 $mpadding 0 0;
    .message-box {
      [textarea] {
        border-radius: $mpadding;
        background-color: rgba(9, 30, 66, 0.01);
        min-height: auto;
        padding: $mpadding;
        margin: $mpadding 0 0 0;
      }
    }
    .confirm {
      margin: $mpadding 0 0 0;
    }
  }
}
*.__loading {
  .complaint-card {
    &-info-label,
    &-info-desc,
    .mount,
    .sign,
    .mount_label,
    .progress-bar,
    .progress-bar-line {
      background-color: #f3f3f3;
      color: rgba(0, 0, 0, 0);
      animation: ItermitColor 6s ease infinite alternate;
    }
  }
}
</style>
